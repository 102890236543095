@font-face {
  font-family: 'Constructa';
  src: url('./Constructa-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mystique';
  src: url('./Mystique-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic Condensed';
  src: url('TradeGothicLT-BoldCondTwenty.woff2') format('woff2'),
    url('TradeGothicLT-BoldCondTwenty.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic Condensed';
  src: url('TradeGothicLT-CondEighteen.woff2') format('woff2'), url('TradeGothicLT-CondEighteen.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic Condensed';
  src: url('TradeGothicLTStd-Cn18Obl.woff2') format('woff2'), url('TradeGothicLTStd-Cn18Obl.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic Condensed';
  src: url('TradeGothicLTStd-BoldObl.woff2') format('woff2'), url('TradeGothicLTStd-BoldObl.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic Shortened';
  src: url('TradeGothicLTStd-BoldExt.woff2') format('woff2'), url('TradeGothicLTStd-BoldExt.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic Shortened';
  src: url('TradeGothicLTStd-Extended.woff2') format('woff2'), url('TradeGothicLTStd-Extended.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url('TradeGothicLTStd-Bd2.woff2') format('woff2'), url('TradeGothicLTStd-Bd2.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url('TradeGothicLTStd-Bd2Obl.woff2') format('woff2'), url('TradeGothicLTStd-Bd2Obl.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url('TradeGothicLTStd.woff2') format('woff2'), url('TradeGothicLTStd.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url('TradeGothicLTStd-Light.woff2') format('woff2'), url('TradeGothicLTStd-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url('TradeGothicLTStd-Obl.woff2') format('woff2'), url('TradeGothicLTStd-Obl.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proximity Nova';
  src: url('ProximaNova-Bold.woff') format('woff'), url('ProximaNova-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
